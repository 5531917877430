import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, AfterViewInit, HostListener, ChangeDetectionStrategy, inject, ChangeDetectorRef } from '@angular/core';
import * as RoutesDefinitions from 'apps/middle/src/app/routes-definitions';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { ActionType, UnsubscriberClass } from '@aston/foundation';
import { NavigationEnd, Router } from '@angular/router';
import { IAction } from '@aston/foundation';
import { Location } from '@angular/common';

import { HomeRouteService } from '../../../shared-module/services';
import { IUser } from '../../../authentication-module/models';

@Component({
	selector: '[layout-menu]',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: []
})
export class MenuComponent extends UnsubscriberClass implements OnInit, AfterViewInit {

	protected hrs = inject(HomeRouteService)
	protected cd = inject(ChangeDetectorRef)
	protected location = inject(Location)
	protected router = inject(Router)

	routes = {
		homePage:									RoutesDefinitions.getHomeFullPath(),
		overviewPage:								RoutesDefinitions.getOverviewFullPath(),
		opOverviewPage:								RoutesDefinitions.getOverviewFullPath(RoutesDefinitions.DashboardsPageOperationalRoutePath),
		fiOverviewPage:								RoutesDefinitions.getOverviewFullPath(RoutesDefinitions.DashboardsPageFinancialRoutePath),
		teamDashboardPage:							RoutesDefinitions.getTeamDashboardRoutePathFullPath(),
		sellersList: 								RoutesDefinitions.getSellersListFullPath(),
		superDebtorsList: 							RoutesDefinitions.getSuperDebtorsListFullPath(),
		accountingList: 							RoutesDefinitions.getAccountingListFullPath(),
		accountingImportsList: 						RoutesDefinitions.getAccountingImportsListFullPath(),
		accountingLettering: 						RoutesDefinitions.getAccountingLetteringFullPath(),
		transfersList: 						 		RoutesDefinitions.getTransfersListRouteFullPath(),
		paymentOrdersList: 							RoutesDefinitions.getPaymentOrdersListRouteFullPath(),
		dunning: 									RoutesDefinitions.getDunningRouteFullPath(),
		dunningCasesList: 							RoutesDefinitions.getDunningCasesListRouteFullPath(),
		dunningGroupsList: 							RoutesDefinitions.getDunningGroupsListRouteFullPath(),
		dunningContactsList: 						RoutesDefinitions.getDunningContactsListRouteFullPath(),
		dunningTemplatesList: 						RoutesDefinitions.getDunningTemplatesListRouteFullPath(),
		dunningScenariosList: 						RoutesDefinitions.getDunningScenariosListRouteFullPath(),
		dunningActionsList: 						RoutesDefinitions.getDunningActionsListRouteFullPath(),
		dunningShipmentHistory:						RoutesDefinitions.getDunningShipmentHistoryRouteFullPath(),
		importRoot: 								RoutesDefinitions.getImportRootFullPath(),
		importCreation: 							RoutesDefinitions.getImportCreationRouteFullPath(),
		importList: 						 		RoutesDefinitions.getImportListRouteFullPath(),
		importProvidersList: 						RoutesDefinitions.getImportProvidersListRouteFullPath(),
		adminToolsPage: 							RoutesDefinitions.getAdminToolsPageRouteFullPath(),
		adminToolsOptionsPage: 						RoutesDefinitions.getAdminToolsOptionsRouteFullPath(),
		adminToolsTransfers: 						RoutesDefinitions.getAdminToolsTransfersRouteFullPath(),
		adminToolsSuperDebtorDunning: 				RoutesDefinitions.getAdminToolsSuperDebtorDunningRouteFullPath(),
		adminToolsIndicators:						RoutesDefinitions.getAdminToolsIndicatorsFullPath(),
		adminToolsClaimTypes:						RoutesDefinitions.getAdminToolsClaimTypesFullPath(),
		adminToolsWorkers: 							RoutesDefinitions.getAdminToolsWorkersRouteFullPath(),
		adminToolsWorkflowSchedulingInstancesList:	RoutesDefinitions.getAdminToolsWorkflowSchedulingInstancesListRouteFullPath(),
		adminToolsWorkflowSchedulingConfig: 		RoutesDefinitions.getAdminToolsWorkflowSchedulingConfigRouteFullPath(),
		adminToolsWorkflowSchedulingLauncher: 		RoutesDefinitions.getAdminToolsWorkflowSchedulingLauncherRouteFullPath(),
		adminToolsExtradataConfig:					RoutesDefinitions.getAdminToolsExtradataConfigFullPath(),
		adminToolsPortfolioRights:					RoutesDefinitions.getAdminToolsPortfolioRightsFullPath(),
		adminToolsUserReports:						RoutesDefinitions.getAdminToolsUserReportsFullPath(),
		adminToolsUsers:							RoutesDefinitions.getAdminToolsUsersFullPath(),

	};

	@Output() action: EventEmitter<IAction> = new EventEmitter();
	@Input() hasManualImportClearanceLevel: boolean;
	@Input() hasImportHistoryClearanceLevel: boolean;
	@Input() hasLetteringClearanceLevel: boolean;
	@Input() hasPaymentOrderClearanceLevel: boolean;
	@Input() hasAdministrationToolsClearanceLevel: boolean;
	@Input() hasSuperAdministrationClearanceLevel: boolean;
	@Input() hasTeamFollowUpClearanceLevel: boolean;
	@Input() hasReadOnlyAccessToAllSuperDebtorsClearanceLevel: boolean;
	@Input() hasDunningClearanceLevel: boolean;
	@Input() currentUser: IUser;

	@ViewChild('menulinks') menu: ElementRef<any>;

	sellersListRegExp = new RegExp('^' + RoutesDefinitions.getSellersListFullPath());
	transferControlRegExp = new RegExp('^' + RoutesDefinitions.getTransferDetailRouteFullPath(-1, -1).replace(/-1/g, '[0-9]*'));

	ngOnInit() {
		if (this.hasSuperAdministrationClearanceLevel && !this.hasAdministrationToolsClearanceLevel) {
			this.routes.adminToolsPage = this.routes.adminToolsWorkers;
		}
	}

	ngAfterViewInit() {
		// This can be used to sync expanded menu with the navigation
		// In this case, the HostListener should be removed.
		// this.syncExpandedMenuWithNavigation();

		// This can be used to expand the menu of the first page only
		this.syncExpandedMenuWithCurrentPage();
		this.location.onUrlChange(_ => this.syncExpandedMenuWithCurrentPage())
	}

	syncExpandedMenuWithCurrentPage() {
		let link = null;
		let path = this.router.url
		do {
			link = this.menu.nativeElement.querySelector(`a[href*="${path}"]`);
			path = link ? path : path.split('/').slice(0, -1).join('/')
		} while (!link && path.split('/').length > 1)

		console.debug('[menu] sync menu from url %o: %o', path, link);
		this.collapseOtherMenus(link);
		this.cd.markForCheck();
	}

	syncExpandedMenuWithNavigation() {
		this.router.events.pipe(
			filter(e => e instanceof NavigationEnd),
			map(e => (e as NavigationEnd).urlAfterRedirects),
			startWith(this.router.url),
			takeUntil(this.destroySubscriptions$)
		).subscribe(url => {
			const link = this.menu.nativeElement.querySelector(`a[href~="${url}"]`);
			console.debug('[menu] sync menu from url %o: %o', url, link);
			this.collapseOtherMenus(link);
		})
	}

	@HostListener('click', ['$event.target'])
	collapseOtherMenus(btn: HTMLDivElement) {
		const hasMenuIcon = (el?: Element): boolean => el?.classList.contains('menu-icon')
		const fromTitle = btn && (hasMenuIcon(btn.previousElementSibling) || hasMenuIcon(btn.firstElementChild));

		let newParent = btn;
		while (btn && newParent && !(newParent.parentNode as HTMLDivElement)?.classList?.contains('menu-links')) {
			newParent = newParent.parentNode as HTMLDivElement;
		}

		[...this.menu.nativeElement.children]
			.forEach(e => e.classList.add('menu-collapsed'));

		if (newParent) {
			if (fromTitle)
				newParent.classList.toggle('menu-collapsed');
			else
				newParent.classList.remove('menu-collapsed');
		}
	}

	onToggle() {
		this.action.emit({ type: ActionType.TOGGLE });
	}

	onDunningActionForm(url: string) {
		return !!(url.includes(this.routes.dunning) && url.match(/(doActionId|showActionId)/))
	}

	specialActiveCases(path: string): boolean {
		return false
			|| (path === this.routes.sellersList && !!this.router.url.match(this.sellersListRegExp) && !this.router.url.match(this.transferControlRegExp))
			|| (path === this.routes.transfersList && this.router.url === this.routes.transfersList)
			|| (path === this.routes.transfersList && !!this.router.url.match(this.transferControlRegExp))
			|| (path === this.routes.superDebtorsList && this.router.url.includes(this.routes.superDebtorsList) && !this.onDunningActionForm(this.router.url))
			|| (path === this.routes.dunning && (
				this.router.url.includes(this.routes.dunningActionsList)
				|| this.router.url.includes(this.routes.dunningCasesList)
				|| this.router.url.includes(RoutesDefinitions.DunningSettingRoutePath)
				|| this.onDunningActionForm(this.router.url)))
			;
	}
}
